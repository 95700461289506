import PrivacyC from 'components/LinksComponents/PrivacyC'
import MainLayout from 'layouts/MainLayout'
import React, { useEffect, useState } from 'react'
import Helmet from 'ui/Helmet/Helmet'

const Privacy = () => {
  const [description , setDescription] = useState("")
  const getdataprivacy = ()=>{
    fetch('https://x-freee.com/api/api/page/Privacy')
      .then((res) => res.json())
      .then((data) => {
        setDescription(data.description)
      });
  } 
    useEffect(()=>{
      getdataprivacy()
    })
  return (
    <MainLayout>
      <Helmet title="سياسة الخصوصية"/>
      <PrivacyC>
        <div dangerouslySetInnerHTML={{__html: description}} className='px-3'></div>
      </PrivacyC>
    </MainLayout>
  )
}

export default Privacy