import React from 'react';
import './ViewRequest.css';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const ViewRequest = () => {
  const product = useSelector((state)=>state.products.data);
  const loading = useSelector((state)=>state.products.loading);
  return (
    <div className='vQ'>
        <Container>
            <div className='view'>
                <i className="ri-eye-line"></i>
                <span className='span'>{!loading && product.fields?.view?.integerValue}</span><span>مشاهدة</span>
            </div>
            <div className='request'>
                <i className="ri-shopping-bag-line"></i>
                <span className='span'>{!loading && product.fields?.copy?.integerValue}</span><span>طلب</span>
            </div>
        </Container>
    </div>
  )
}

export default ViewRequest