import React from 'react';
import './Footer.css';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer className='footer'>
      <Container>
        <div className='linksFooter'>
          <Link to='/privacy'>سياسة الخصوصية</Link>
          <Link to='/about'>من نحن</Link>
          <Link to='/terms'>شروط الإستخدام</Link>
        </div>
        <div className='mail_footer'>
          <h3>ضع بريدك الإلكتروني</h3>
          <div className='mailIput'>
            <form onSubmit={(e)=>e.preventDefault()}>
              <input type='email' placeholder='ضع بريدك ليصلك كل جديد'/>
              <button type='submit'>اشتراك</button>
            </form>
          </div>
          <p>جميع الحقوق محفوظة ل <span>وادي الكوبونات </span>2023</p>
        </div>
      </Container>
    </footer>
    )
}

export default Footer