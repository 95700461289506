import React from 'react'
import { Container } from 'react-bootstrap'
import './Privacy.css'
const PrivacyC = (props) => {
  return (
    <div className='privacyDiv'>
        <Container>
            <h1 className='text-center mb-4'>سياسة الخصوصية</h1>
            {props.children}
        </Container>
    </div>
  )
}

export default PrivacyC