import React, { useEffect, useState } from 'react';
import './CardDetails.css';
import {Container } from 'react-bootstrap';
import ProductImagesSlider from 'ui/product-images-slider/ProductImagesSlider';


import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import BuyBtn from 'ui/buy_button/BuyBtn';
import { useSelector } from 'react-redux';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from 'firebase.config';


const CardDetails =  () => {
  const product = useSelector((state)=>state.products.data);
  const loadingProduct = useSelector((state)=>state.products.loading);

  const [idss,setIdss] = useState([])
  
  
      
  const[data, setData] = useState([]) 
  const [loading, setLoading] = useState()
  const fetchData = async () => {
    setLoading(false)
    if(loadingProduct === false){
      await product.fields?.store_url?.arrayValue?.values?.forEach(element => {
      setIdss((state) =>[...state,element.mapValue?.fields.urlStore.stringValue])})
      idss.length > 0 ? setLoading(false) : setLoading(true)
    }
    if(loading === false && idss.length >0){
      const docRef= collection(db,'store');
      const qq = query(docRef, where("__name__" , 'in', idss))
      onSnapshot(qq,(snapshot)=>{
        let books = [];
        snapshot.docs.forEach((doc)=>{
          books.push({...doc.data(), id:doc.id})
        })
        setData(books)
        
      })
    }
}
  useEffect(()=>{ 
       fetchData()   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[loading,loadingProduct])   
    return (
      <div className="card_details">
        <div className="row_card">
          <div className="card_content">
            <div className="card_content_header">
              {!loadingProduct && (
                <>
                  <h2>{product?.fields?.title?.stringValue}</h2>
                  <span>من {data[0]?.name}</span>
                </>
              )}
            </div>
            <div className="card_content_rating">
              <i className="ri-star-line"></i>
              <span>4.5</span>
            </div>
            <h2 className="mt-3 headbtn_1">اشتري الآن من</h2>
            {!loading &&
              data?.map((item) => (
                <BuyBtn
                  key={item.id}
                  storeName= {item.name}
                  discount={parseInt(item.discount_percentage)}
                  price={
                    product.fields.price.stringValue
                      ? parseInt(
                          product.fields.price.stringValue?.match(/\d+/g),
                        )
                      : 100
                  }
                  code={item.code}
                  class="card_content_btn"
                  storeLogo={item.img}
                  product_url={
                    product.fields.store_url.arrayValue?.values?
                     product.fields.store_url.arrayValue?.values?.find(
                          (it) => it.mapValue ? it.mapValue.fields.urlStore.stringValue === item.id : ''
                        )?.mapValue?.fields.urlId.stringValue
                      : '#'
                  }
                  productId={
                    product.name.split('/')[product.name.split('/').length - 1]
                  }
                  urlId={item.id}
                />
              ))}
          </div>
          <div className="ImgSlider">
            {
              <ProductImagesSlider
                discount={parseInt(data[0]?.discount_percentage)}
              />
            }
          </div>
        </div>
        <Container className="pt-3">
          <h2 className="mt-2 headbtn_2">اشتري الآن من</h2>
        </Container>
        <div className="buy_btn_div">
          {!loading &&
            data?.map((item) => (
              <BuyBtn
                key={item.id}
                storeName= {item.name}
                discount={parseInt(item.discount_percentage)}
                price={
                  product.fields.price.stringValue
                    ? parseInt(product.fields.price.stringValue?.match(/\d+/g))
                    : 100
                }
                code={item.code}
                class="card_content_btn2"
                storeLogo={item.img}
                product_url={
                  product.fields.store_url.arrayValue?.values?
                   product.fields.store_url.arrayValue?.values?.find(
                        (it) => it.mapValue ? it.mapValue.fields.urlStore.stringValue === item.id : '' 
                      )?.mapValue?.fields.urlId.stringValue
                    : '#'
                }
                productId={
                  product.name.split('/')[product.name.split('/').length - 1]
                }
                urlId={item.id}
              />
            ))}
        </div>
      </div>
    );
}

export default CardDetails