import React, { useEffect, useState } from 'react';
import './BuyLimit.css';
import Modal from 'ui/modal/Modal';
import successImg from'assets/images/success.png';
import { useSelector } from 'react-redux';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from 'firebase.config';

const BuyLimit = () => {
    const [show , setShow] = useState(false);
    const handle = ()=>{
        setShow(true);
        setErrors({})
        setName("")
        setAddress("")
        setPhone("")
    }
    const handleClose = ()=>{
        setShow(false);
        setSuccess(false)
    }
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [Address, setAddress] = useState('');
    const [errors, setErrors] = useState({});
    const [success,setSuccess]= useState(false);
    const [data, setData] = useState();

    useEffect(()=>{
      fetchRequest()
    }) 
    const fetchRequest = async ()=>{
      const docRef= collection(db,`products/${product.name?.split('/')[product?.name.split('/').length - 1]}/requests`);
      const qq = query(docRef, where("name" , '==', name.trim()),where("phone" , '==', phone.trim()),where("address" , '==', Address.trim()))
      onSnapshot(qq,(snapshot)=>{
        let books = [];
        snapshot.docs.forEach((doc)=>{
          books.push({...doc.data(), id:doc.id})
        })
        setData(books)
      })
    }  


    const handle_submit = (e)=>{
        e.preventDefault();
        let errors ={};
          if (!name.trim()) {
            errors.name = 'Name is required';
          }
      
          if (!phone.trim()) {
            errors.phone = 'phone is required';
          } else if (phone.length < 11) {
            errors.phone = 'invalid phone number';
          }
      
          if (!Address.trim()) {
            errors.address = 'Address is required';
          } 
          setErrors(errors);
          if (Object.keys(errors).length === 0) {
              if (
                data.find((el) => el.name === name.trim())?.name === name.trim() &&
                data?.find((el) => el.phone === phone.trim())?.phone === phone.trim() &&
                data?.find((el) => el.address === Address.trim())?.address === Address.trim()
              ){
                errors.found = "data entered before";
              } else {
                setSuccess(true);
                createProductRequest(e);
              }
          }
        }
const product = useSelector((state)=>state.products.data);
const createProductRequest =async (e)=>{ 
  e.preventDefault();
  try {
      await fetch(`https://firestore.googleapis.com/v1/projects/xfreeestore/databases/(default)/documents/products/${product.name.split('/')[product.name.split('/').length - 1]}/requests`,
                          {method:'POST' ,
                          body: JSON.stringify({       
                                fields:{
                                  name: {stringValue: name.trim()},
                                  phone: {stringValue: phone.trim()},
                                  address: {stringValue: Address.trim()},
                                }})
                    });      
          console.log('product added successfully');
  }
  catch (error) {
              console.log(error);
              console.log("data not added!");  
          }
      }
  return (
    <div className='buyLimit'>
        <button className='buyLimit_btn' onClick={handle}>للشراء عبرنا اضغط هنا</button>
        {
            !success ? 
            <Modal visible={show} class='overlay_show_two' closeModal = {handleClose}>
                <div className='closeIcon' onClick={handleClose}><i className="ri-close-circle-line"></i></div>  
                <h2>ادخل معلوماتك لاكتمال طلبك</h2>
                <form onSubmit={handle_submit}>
                {errors.found && <label className='error_msg'>{errors.found}</label> }

                    <input className={errors.name ? 'limitInput error' : 'limitInput'} type='text' placeholder='الاسم الثلاثي...' value={name} onChange={(e) => setName(e.target.value)}/>
                    {errors.name && <label className='error_msg'>{errors.name}</label> }
                    <input className={errors.phone ? 'limitInput error' : 'limitInput'} type='tel' placeholder='رقم الهاتف' value={phone} onChange={(e) => setPhone(e.target.value)}/>
                    {errors.phone && <label className='error_msg'>{errors.phone}</label> }
                    <textarea className={errors.address ? 'limitInput error' : 'limitInput'} placeholder='العنوان بالتفصيل' value={Address} onChange={(e) => setAddress(e.target.value)}></textarea>
                    {errors.address && <label className='error_msg'>{errors.address}</label> }
                    <button className='buylimit_submit' type='submit'>اضغط هنا للشراء</button>
                </form>
            </Modal>
            :
            <Modal visible={show} class='overlay_show_two successOverLay'>
                <div className='closeIcon' onClick={handleClose}><i className="ri-close-circle-line"></i></div>  
                <div className='success_overlayImg'>
                    <img src={successImg} alt='thankU'/>
                </div>
                <h2>تم إرسال طلبك بنجاح</h2>
                <button className='buylimit_submit'>شراء منتجات اخرى</button>
            </Modal>
        }
    </div>
  )
}

export default BuyLimit