import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "remixicon/fonts/remixicon.css";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { Provider } from 'react-redux';
import { Store } from 'rtk/Store';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  <BrowserRouter>
  <ToastContainer
      position="center-center"
      autoClose={3000}
      closeOnClick
      pauseOnHover={false}
      theme="light"
    />
    <Provider store={Store}>
      <App />
    </Provider>
  </BrowserRouter>
  </>
);

