import CardDetails from 'components/product_details/card_details/CardDetails'
import Details from 'components/product_details/details/Details'
import LimitOffer from 'components/product_details/limitOffer/LimitOffer'
import MoreDetails from 'components/product_details/moreDetails/MoreDetails'
import ViewRequest from 'components/product_details/view_request/ViewRequest'
import MainLayout from 'layouts/MainLayout'
import React from 'react'
import { useParams } from 'react-router-dom'
import Helmet from 'ui/Helmet/Helmet'
import Slick from 'ui/slickSlider/Slick'
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchProducts } from "rtk/ProductSlice";
import { doc, updateDoc } from 'firebase/firestore'
import { db } from 'firebase.config'

const ProductDetails = () => {
  const {id} = useParams();
  const dispatch = useDispatch();

  const collectionRef = doc(db, 'products' , id);
  const viewClickProducts =async (id)=>{
      await fetch(`https://x-freee.com/api/api/product/d/${id}`).then((res)=>res.json()).then((data)=>{
      updateDoc(collectionRef, {
        view: data.view,
        click: data.click,
        copy: data.copy
      });
    })
}

  const viewProduct = ()=>{
              fetch(`https://x-freee.com/api/api/click/view?product_id=${id}`,{
              headers : { "content-Type" : "Application/json"},
              method: "POST"})
              .then((res) => res.json()).then(()=>{
                viewClickProducts(id)
              })
      }
         
  useEffect(()=>{
    dispatch(fetchProducts(id));
    viewProduct()
    window.scrollTo(0,0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  return (
    <MainLayout>
      <Helmet title="تفاصيل المنتج"/>
        <CardDetails/>
        <ViewRequest/>
        <LimitOffer/>
        <Details/>
        <MoreDetails/>
        <Slick/>
    </MainLayout>
  )
}

export default ProductDetails