import MainLayout from "layouts/MainLayout";
import Privacy from "pages/Privacy/Privacy";
import AboutUs from "pages/about/AboutUs";
import ProductDetails from "pages/product_details/ProductDetails";
import Terms from "pages/terms/Terms";
import { Route, Routes } from "react-router-dom";


function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<MainLayout/>}/>
      <Route path="/product/:id" element={<ProductDetails/>}/>
      <Route path="/privacy" element={<Privacy/>}/>
      <Route path="/about" element={<AboutUs/>}/>
      <Route path="/terms" element={<Terms/>}/>
    </Routes>
    </>
  );
}

export default App;
