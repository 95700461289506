import AboutC from 'components/LinksComponents/AboutC';
import MainLayout from 'layouts/MainLayout';
import React, { useEffect, useState } from 'react'
import Helmet from 'ui/Helmet/Helmet';

const AboutUs = () => {
    const [about, setAbout] = useState('');
    const getdataabout = () => {
      fetch('https://x-freee.com/api/api/page/about')
        .then((res) => res.json())
        .then((data) => {
          setAbout(data.description);
        });
    };
    useEffect(() => {
      getdataabout();
    });
  return (
    <MainLayout>
    <Helmet title="من نحن"/>
    <AboutC>
      <div dangerouslySetInnerHTML={{__html: about}} className='px-3'></div>
    </AboutC>
  </MainLayout>
  )
}

export default AboutUs