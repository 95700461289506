import React from 'react'
import { Container } from 'react-bootstrap'

const TermsC = (props) => {
  return (
    <div className='privacyDiv'>
        <Container>
            <h1 className='text-center mb-4'>شروط الإستخدام</h1>
            {props.children}
        </Container>
    </div>
  )
}

export default TermsC