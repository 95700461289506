import Footer from 'components/layouts/footer/Footer'
import Header from 'components/layouts/header/Header'
import React from 'react'
import Helmet from 'ui/Helmet/Helmet'

const MainLayout = (props) => {
  return (
    <>
        <Header/>
        <Helmet title=" "/>
        <main>
            {props.children}
        </main>
        <Footer/>
    </>
  )
}

export default MainLayout