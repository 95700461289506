import React from 'react';
import ReactDOM  from 'react-dom';
import './Modal.css'
const Modal = (props) => {
    if(!props.visible){
        return null;
    }
  return ReactDOM.createPortal(
    <div className='overlay d-flex align-items-center justify-content-center' onClick={props.closeModal}>
        <div className={props.class} onClick={(e)=>e.stopPropagation()}>
            {props.children}
        </div>
    </div>,
    document.querySelector('#modal_root')
  )
}

export default Modal