import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './Slick.css';
import { db } from 'firebase.config';
import { collection, limit, onSnapshot, query } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const Slick = (props) => {
    const settings = {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          }
        ]
    };
    const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const dataRef = await collection(db,'products')
      // createTime
      const qq = query(dataRef, limit(10))
      onSnapshot(qq,(snapshot)=>{
        let books = [];
        snapshot.docs.forEach((doc)=>{
          books.push({...doc.data(), id:doc.id})
        })
        // console.log(books);
        setCards(books)
      })
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="slickContainer">
        <h2 className='headbtn_1 headLimit1'>منتجات مقترحة</h2>
        <h2 className='headbtn_2 headLimit'>منتجات مقترحة</h2>
        <Slider {...settings}>
          {cards?.map((item) => (
            <div className="itemCard" key={item.id}>
              <div className="imgContainer">
                <img src={item.img} alt="product-img" className="img" />
              </div>
              <div className="headCard">
                <h2 className="title">{item.title} </h2>
              </div>
              <div className="slick_discount">
                <Link to={`/product/${item.id}`}><h3>%خصم يصل إلى 70</h3></Link>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default Slick