import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const fetchProducts = createAsyncThunk("productSlice/fetchProducts", async(id)=>{
    const res = await fetch(`https://firestore.googleapis.com/v1/projects/xfreeestore/databases/(default)/documents/products/${id}`);
    const data = await res.json();
    return data;
})
const initialState = 
{
    data: [],
    loading: true
};
const productSlice = createSlice({
    initialState,
    name: "productSlice",
    reducers: {},
    extraReducers:(builder)=>{
        builder.addCase(fetchProducts.pending, (state,action)=>{
            state.loading = true;
        })
        builder.addCase(fetchProducts.fulfilled, (state,action)=>{
            state.loading = false;
            state.data = action.payload;
        })
        builder.addCase(fetchProducts.rejected, (state,action)=>{
            state.loading = false;
            state.data = [];
        })
    }

})

// eslint-disable-next-line no-empty-pattern
export const {} = productSlice.actions;
export default productSlice.reducer