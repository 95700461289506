import React, { useRef, useState } from 'react'
import './BuyBtn.css'
import Modal from 'ui/modal/Modal';
import { useSelector } from 'react-redux';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from 'firebase.config';

const BuyBtn = (props) => {
    const [show , setShow] = useState(false);
    const [shopNowBtn, setShopNowBtn] = useState(false);
    const collectionRef = doc(db, 'products' , props.productId);
    const viewClickProducts =async (id)=>{
        await fetch(`https://x-freee.com/api/api/product/d/${props.productId}`).then((res)=>res.json()).then((data)=>{
        updateDoc(collectionRef, {
          view: data.view,
          click: data.click,
          copy: data.copy
        });
      })
    }

    const handle = ()=>{
        setShow(true);
        fetch(`https://x-freee.com/api/api/click/save?product_id=${props.productId}&url_id=${props.urlId}`,{
              headers : { "content-Type" : "Application/json"},
              method: "POST"})
              .then((res) => res.json()).then(()=>{
                viewClickProducts(props.productId)
              })
    }


    const inputRef = useRef(null);
    const handleCopy = ()=>{
        inputRef.current.select();
        document.execCommand('copy');
        setShopNowBtn(true);
        fetch(`https://x-freee.com/api/api/click/copy?product_id=${props.productId}&url_id=${props.urlId}`,{
            headers : { "content-Type" : "Application/json"},
            method: "POST"})
            .then((res) => res.json()).then(()=>{
                viewClickProducts(props.productId)})
      }
  
      const loading = useSelector((state)=>state.products.loading);
  
  
return (
    <>
        <div  className={props.class}>
            <div className='btn_right'>
                <p>السعر :  {props.price} ر.س</p>
            </div>
            <div className='btn_left d-flex align-items-center'>
                <div className="store_logo">
                    {props.children}
                    <img src={props.storeLogo} alt='storeLogo'/>
                </div>
                <button onClick={handle}> <i className="ri-shopping-cart-line"></i> اشتري الآن</button>
            </div>
        </div>
                {
                    !loading &&
        <Modal visible={show} class='overlay_show' closeModal = {()=>{setShow(false) ; setShopNowBtn(false)}}>
            <span className='closeIcon' onClick={()=>{setShow(false) ; setShopNowBtn(false)}}><i className="ri-close-circle-line"></i></span>  
            <div className='modal_header'>
                <img src={props.storeLogo} alt='storeLogo'/>
                <h3>خصم {props.discount}% من {props.storeName}</h3>
            </div>
            <div className='modal_body'>
                <p className='mb-2'>استخدم الكود أثناء الشراء واحصل على {props.discount}% خصم</p>
                     <p>ليصبح السعر <span className='price_before'>{props.price - (props.price * props.discount /100)}</span> بدلاً من <span className='price_after'>{props.price}</span></p>
            </div>
            <div className={`${shopNowBtn?'modal_btn2 mt-3 a' : 'modal_btn mt-3'}`} >
                <input id='name2' type='text' readOnly defaultValue={props.code} ref={inputRef}/>
                {!shopNowBtn ?   
                    <button onClick={handleCopy}>
                        <i className='ri-file-copy-line'></i>
                        نسخ الكود
                    </button>:
                    <a target='blank' href={props.product_url} className='d-flex align-items-center justify-content-center'>
                        <i className='ri-external-link-line'></i>
                        تسوق الآن
                    </a>     
            }
            </div>
        </Modal>
                    }
    </>
  );
}

export default BuyBtn