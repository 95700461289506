import React, { useState } from 'react'
import './ProductImagesSlider.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Thumbs } from 'swiper'
import { useSelector } from 'react-redux'


const ProductImagesSlider = (props) => {
    const [activeThumb, setActiveThumb] = useState(null)
    const product = useSelector((state)=>state.products.data);
    const loading = useSelector((state)=>state.products.loading);

  return (
    <>
      <Swiper
        loop={true}
        spaceBetween={10}
        modules={[Thumbs]}
        grabCursor={true}
        thumbs={{ swiper: activeThumb }}
        className="product-images-slider"
      >
        {
        !loading &&
        <div className='offerProduct'><p>خصم {props.discount}%</p></div>
        }
        {
        !loading &&
         product?.fields?.img.arrayValue.values?.map((item,i) => (
          <SwiperSlide key={i}>
            <img src={item.stringValue} alt="product-img" />
          </SwiperSlide>
        ))
      }
      </Swiper>
      <Swiper
        loop={false}
        onSwiper={setActiveThumb}
        spaceBetween={10}
        slidesPerView={product.product_images?.length}
        modules={[Thumbs]}
        className="product-images-slider-thumbs"
      >
        { 
        !loading &&
        product.fields?.img.arrayValue.values?.map((item,i) => (
          <SwiperSlide key={i}>
            <img src={item.stringValue} alt="product-img" />
          </SwiperSlide>
        ))
        }
      </Swiper>
    </>
  );
}
export default ProductImagesSlider