import TermsC from 'components/LinksComponents/TermsC';
import MainLayout from 'layouts/MainLayout';
import React, { useEffect, useState } from 'react'
import Helmet from 'ui/Helmet/Helmet';

const Terms = () => {
    const [terms , setTerms] = useState("")
    const getdataterms = ()=>{
      fetch('https://x-freee.com/api/api/page/terms')
        .then((res) => res.json())
        .then((data) => {
          setTerms(data.description)
        });
    } 
      useEffect(()=>{
        getdataterms()
      })
  return (
    <MainLayout>
    <Helmet title="شروط الإستخدام"/>
    <TermsC>
      <div dangerouslySetInnerHTML={{__html: terms}} className='px-3'></div>
    </TermsC>
  </MainLayout>
  )
}

export default Terms