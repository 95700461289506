import React from 'react'
import { Container } from 'react-bootstrap'

const AboutC = (props) => {
  return (
    <div className='privacyDiv'>
    <Container>
        <h1 className='text-center mb-4'>من نحن</h1>
        {props.children}
    </Container>
</div>
  )
}

export default AboutC