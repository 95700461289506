import React from 'react';
import './Header.css';
import { Container } from 'react-bootstrap';
import Logo from 'assets/images/logo.png';
const Header = () => {
  return (
    <header className='header'>
      <Container>
        <div className='logo'>
          <img src={Logo} alt='logo_img'/>
        </div>
        <div className='menu'>
          <i className="ri-list-unordered"></i>
        </div>
      </Container>
    </header>
  )
}

export default Header