import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import './Details.css'
import { useSelector } from 'react-redux';
const Details = () => {
  const [description , setDescription] = useState("");
  const product = useSelector((state)=>state.products.data);
  const loading = useSelector((state)=>state.products.loading);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(()=>{
    !loading &&
    setDescription(product.fields?.description.stringValue)
  })
  return (
    <div className='details_list'>
       
        <h2 className='headbtn_1 headLimit1'>وصف المنتج</h2>
        <h2 className='headbtn_2 headLimit'>وصف المنتج</h2>
        <Container>
        <div dangerouslySetInnerHTML={{__html: description}} className='px-3 detailsUlList'>
        </div>
        </Container>
    </div>
  )
}

export default Details