import React, { useEffect, useState } from 'react';
import './LimitOffer.css';
import { Container } from 'react-bootstrap';
import BuyLimit from 'ui/BuyLimit/BuyLimit';
import { useSelector } from 'react-redux';

const LimitOffer = () => {
    
    const [days , setDays] = useState('');
    const [hours , setHours] = useState('');
    const [minutes , setMinutes] = useState('');
    const [seconds , setSeconds] = useState('');

    let interval;
    const product = useSelector((state)=>state.products.data);
    const loading = useSelector((state)=>state.products.loading);

    const countDown = ()=>{
        const destination =!loading && new Date(product.fields?.date?.stringValue).getTime(); 
        interval = setInterval(() => {
           const now = new Date().getTime(); 
           const different = destination - now;

           const days = Math.floor(different / (1000*60*60*24));
           const hours = Math.floor(different % (1000*60*60*24)/(1000*60*60))
           const minutes = Math.floor(different % (1000*60*60)/(1000*60))
           const seconds = Math.floor(different % (1000*60)/(1000))

           if (different < 0) {
                clearInterval(interval.current)
           }else{
                setDays(days);
                setHours(hours);
                setMinutes(minutes);
                setSeconds(seconds);
           }
        });
    }
    useEffect(()=>{
        countDown()
    }) 
  return (
    <>
        <div className="limitOffer">
        <h2 className="headbtn_1 headLimit1">الوقت المتبقي على الخصم</h2>
        <h2 className="headbtn_2 headLimit">الوقت المتبقي على الخصم</h2>
        <div className="limitOfferSection">
            <Container>
            <div className="countDownContent">
                <div className="time">
                <div className="timeNum">{seconds ? seconds : 0}</div>
                <div className="timelabel">ثواني</div>
                </div>
                <div className="time">
                <div className="timeNum">{minutes ? minutes : 0}</div>
                <div className="timelabel">دقائق</div>
                </div>
                <div className="time">
                <div className="timeNum">{hours ? hours : 0}</div>
                <div className="timelabel">ساعات</div>
                </div>
                <div className="time">
                <div className="timeNum">{days ? days : 0}</div>
                <div className="timelabel">أيام</div>
                </div>
            </div>
            </Container>
        </div>
        </div>
        <BuyLimit/>
    </>
  );
}

export default LimitOffer