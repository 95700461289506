import React, { useEffect, useState } from 'react'
import './MoreDetails.css'
import { useSelector } from 'react-redux';

const MoreDetails = () => {
    const [details , setDetails] = useState("");
    const product = useSelector((state)=>state.products.data);
    const loading = useSelector((state)=>state.products.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(()=>{
        !loading &&
        setDetails(product.fields?.details?.stringValue)
      })
  return (
    <div className="more_details">
      <h2 className="headbtn_1 headLimit1">تفاصيل المنتج</h2>
      <h2 className="headbtn_2 headLimit">تفاصيل المنتج</h2>
        <div
          dangerouslySetInnerHTML={{ __html: details }}
          className="more_details_List more_details_List2"
        ></div>
    </div>
  ); 
}

export default MoreDetails